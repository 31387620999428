<template>
  <div>
    <dashboard-navbar
      searchHint="Buscar por nome, CPF, código ou curso"
      @update-search="updateSearch"
    ></dashboard-navbar>

    <div class="container-fluid d-flex h-100 flex-column pt-6 px-2">
      <base-loader v-if="isLoading"></base-loader>
      <projects-table
        v-else
        :key="page"
        :summary-method="calculateSummaries"
        show-summary
        class="table-responsive table-flush"
        header-row-class-name="table-head"
        header-align="center"
        cell-class-name="reduce-padding"
        :row-class-name="getRowClassNames"
        type="hover"
        :tableData="payments"
        title="Repasse do mês"
        @showModal="showAddStudentModal = $event"
      >
        <template #actions>
          <div class="row justify-content-end mr-0">
            <el-tabs v-model="page">
              <el-tab-pane
                label="Previsão"
                value="payments"
                name="payments"
              ></el-tab-pane>
              <el-tab-pane
                label="Repasse"
                value="incoming"
                name="incoming"
              ></el-tab-pane>

              <el-tab-pane
                label="Conferência"
                value="report"
                name="report"
                v-if="userCan('comissions.r')"
              ></el-tab-pane>
            </el-tabs>
            <div class="col-md-3 col-sm-4 pr-0">
              <base-button
                type="primary"
                class="mt-2"
                icon="fa fa-filter"
                title="Filtrar"
                size="sm"
                v-on:click.stop.prevent="openFilterModal"
              ></base-button>

              <el-popover
                placement="bottom"
                :width="300"
                v-model="dialogVisible"
              >
                <p>Em qual formato deseja baixar?</p>
                <div style="text-align: right; margin: 0">
                  <base-button
                    size="sm"
                    type="text"
                    disabled
                    @click="dialogVisible = false"
                    >Excel</base-button
                  >
                  <base-button
                    size="sm"
                    type="text"
                    @click="() => generateCSV((dialogVisible = false))"
                    >CSV</base-button
                  >
                  <base-button
                    type="text"
                    size="sm"
                    v-on:click.stop.prevent="
                      () => generatePDF((dialogVisible = false))
                    "
                    >PDF</base-button
                  >
                </div>
                <template #reference>
                  <base-button
                    type="primary"
                    icon="fa fa-download"
                    class="mt-2"
                    size="sm"
                    :disabled="isLoadingGeneratePDF"
                  ></base-button>
                </template>
              </el-popover>
              <base-button
                v-if="$store.state.user.canPreview"
                type="primary"
                class="mt-2"
                icon="fa fa-upload"
                size="sm"
                v-on:click.stop.prevent="() => this.openUploadDataModal()"
              ></base-button>
            </div>
          </div>
        </template>
        <template #columns v-if="page === 'incoming'">
          <th v-for="col in TableColumns" :key="col">
            {{ col.label }}
            <span
              class="caret-wrapper"
              v-if="col?.sortable"
              @click="sortColumn(col)"
            >
              <i
                :class="{
                  'fa fa-sort-up ascending sort-icon': true,
                  'is-sorted': sortOptions[col.prop] === 'asc',
                }"
              ></i>
              <i
                :class="{
                  'fa fa-sort-down descending sort-icon': true,
                  'is-sorted': sortOptions[col.prop] === 'desc',
                }"
              ></i>
            </span>
          </th>
        </template>
        <template #columns v-else-if="page === 'payments'">
          <th v-for="col in TableColumnsOpenedPayments" :key="col">
            {{ col.label }}
            <span
              class="caret-wrapper"
              v-if="col?.sortable"
              @click="sortColumn(col)"
            >
              <i
                :class="{
                  'fa fa-sort-up ascending sort-icon': true,
                  'is-sorted': sortOptions[col.prop] === 'asc',
                }"
              ></i>
              <i
                :class="{
                  'fa fa-sort-down descending sort-icon': true,
                  'is-sorted': sortOptions[col.prop] === 'desc',
                }"
              ></i>
            </span>
          </th>
        </template>

        <template v-if="page === 'report'" #default>
          <div class="col-12">
            <div class="row justify-content-end mx-3 pb-2">
              <h3>
                {{ CurrentMonthInAgenda }}
              </h3>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <stats-card
                  title="total de pagamentos"
                  type="gradient-info"
                  :subTitle="`R\$ ${currencyFormatter.format(
                    Number(reportData['incomingTotal'] || 0)
                  )}`"
                  class="mb-4 mb-xl-0"
                  icon="fa fa-money-check-alt"
                >
                </stats-card>
              </div>
              <div class="col-md-6 col-sm-12">
                <stats-card
                  title="repasse esperado"
                  type="gradient-info"
                  :subTitle="`R\$ ${currencyFormatter.format(
                    Number(reportData['incomingValuePaid'] || 0)
                  )}`"
                  class="mb-4 mb-xl-0"
                  icon="fa fa-dollar"
                  ><template #footer>
                    <h5 class="text-muted">
                      <span class="text-danger">
                        {{
                          currencyFormatter.format(
                            (Number(reportData["incomingValuePaid"] || 0) /
                              Number(reportData["incomingTotal"] || 0)) *
                              100 || 0
                          )
                        }}
                        % </span
                      >do total de pagamentos
                    </h5></template
                  >
                </stats-card>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <stats-card
                  title="conferência do repasse"
                  type="gradient-info"
                  :subTitle="`R\$ ${currencyFormatter.format(
                    Number(reportData['incomingValueReceived'] || 0)
                  )}`"
                  class="mb-4 mb-xl-0"
                  icon="fa fa-dollar"
                  ><template #footer>
                    <h5 class="text-muted">
                      <span class="text-success">
                        {{
                          currencyFormatter.format(
                            (Number(reportData["incomingValueReceived"] || 0) /
                              (Number(
                                reportData["incomingValueReceived"] || 0
                              ) +
                                Number(reportData["incomingValuePaid"] || 0))) *
                              100 || 0
                          )
                        }}
                        % </span
                      >do total recebível do mês
                    </h5></template
                  >
                </stats-card>
              </div>
              <div class="col-md-6 col-sm-12">
                <stats-card
                  title="pendência do repasse"
                  type="gradient-info"
                  :subTitle="`R\$ ${currencyFormatter.format(
                    Number(reportData['incomingValuePaid'] || 0) -
                      Number(reportData['incomingValueReceived'] || 0)
                  )}`"
                  class="mb-4 mb-xl-0"
                  icon="fa fa-dollar"
                  ><template #footer>
                    <h5 class="text-muted">
                      <span class="text-danger">
                        {{
                          currencyFormatter.format(
                            ((Number(reportData["incomingValuePaid"] || 0) -
                              Number(
                                reportData["incomingValueReceived"] || 0
                              )) /
                              Number(reportData["incomingValuePaid"] || 0)) *
                              100 || 0
                          )
                        }}
                        % </span
                      >do valor a receber
                    </h5></template
                  >
                </stats-card>
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-6 col-sm-12"
                v-for="c in Comissions"
                :key="c.uid"
              >
                <stats-card
                  :title="c.name"
                  type="gradient-info"
                  :subTitle="`R\$ ${currencyFormatter.format(
                    Number(paymentsByComissions[c.uid] || 0)
                  )}`"
                  class="mb-4 mb-xl-0"
                  icon="fa fa-dollar"
                  ><template #footer>
                    <h5 class="text-muted">
                      <span class="text-danger">
                        {{
                          currencyFormatter.format(
                            (Number(paymentsByComissions[c.uid] || 0) /
                              Number(reportData["incomingValuePaid"] || 0)) *
                              100 || 0
                          )
                        }}
                        % </span
                      >do repasse esperado
                    </h5></template
                  >
                </stats-card>
              </div>
            </div>
          </div>
        </template>

        <template #data v-if="page === 'incoming'">
          <tr v-for="(c, id) in payments" :key="id">
            <td>
              <div>
                {{ cpfFormatter.format(c.student.cpf) }}
              </div>
            </td>
            <td class="wrap-content">
              <div>{{ c.student.name }}</div>
            </td>
            <td class="wrap-content">
              <div>{{ c.enrol?._course?.name }}</div>
            </td>
            <td>
              <div>{{ c.order }}/{{ c.total }}</div>
            </td>

            <td>
              <div>
                {{ c.category }}
              </div>
            </td>

            <td class="wrap-content">
              <div>
                {{ c.type }}
              </div>
            </td>

            <td>
              <div>
                {{
                  c.expires_at
                    ? dateFormatter.format(new Date(c.expires_at))
                    : "-"
                }}
              </div>
            </td>

            <td>
              <div>
                {{
                  c.paid_at ? dateFormatter.format(new Date(c.paid_at)) : "-"
                }}
              </div>
            </td>

            <td>
              <div>
                {{ c.value ? currencyFormatter.format(Number(c.value)) : "-" }}
              </div>
            </td>

            <td v-if="userCan('comissions.r')">
              <div>{{ c.comission * 100 }} %</div>
            </td>

            <td>
              <div class="btn-group float-right">
                <base-button
                  type="primary"
                  iconOnly
                  size="sm"
                  @click="navigateToRoute(c)"
                  icon="fa fa-external-link-alt"
                ></base-button>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="
                    c.status != 'paid'
                      ? 'Apenas títulos pagos podem ser conferidos'
                      : c.is_received
                      ? 'Este título consta no repasse'
                      : 'Este título não consta no repasse'
                  "
                  placement="top-start"
                >
                  <base-button
                    :type="c.is_received ? 'primary' : 'danger'"
                    iconOnly
                    :disabled="c.status != 'paid'"
                    size="sm"
                    @click="setReceivedPayment(c)"
                    icon="fa fa-check"
                  ></base-button>
                </el-tooltip>
              </div>
            </td>
          </tr>
        </template>
        <template #data v-else-if="page === 'payments'">
          <tr
            v-for="(c, id) in openedPayments"
            :key="id"
            :class="{ unpaid: (c?.student?.payments?.length || 1) > 1 }"
          >
            <td>
              <div>
                {{ cpfFormatter.format(c.student.cpf) }}
              </div>
            </td>
            <td class="wrap-content">
              <div>{{ c.student.name }}</div>
            </td>
            <td class="wrap-content">
              <div>{{ c.enrol._course?.name }}</div>
            </td>
            <td>
              <div>
                {{ currencyFormatter.format(Number(c.cost)) }}
              </div>
            </td>

            <td>
              <div>{{ c.order }}/{{ c.total }}</div>
            </td>

            <td>
              <div>
                {{ c.category }}
              </div>
            </td>

            <td class="wrap-content">
              <div>
                {{ c.type }}
              </div>
            </td>

            <td>
              <div>
                {{
                  c.expires_at
                    ? dateFormatter.format(new Date(c.expires_at))
                    : "-"
                }}
              </div>
            </td>

            <td v-if="userCan('comissions.r')">
              <div>{{ c.comission * 100 }} %</div>
            </td>

            <td class="btn-group float-right">
              <base-button
                type="primary"
                iconOnly
                size="sm"
                @click="openStudentPage(c)"
                icon="fa fa-external-link-alt"
              ></base-button>
              <base-button
                :type="isCheckedThisMonth(c) ? 'primary' : 'secondary'"
                iconOnly
                size="sm"
                @click="() => checkAllUncheckedPayments(c)"
                icon="el el-icon-finished"
              ></base-button>
              <base-button
                type="primary"
                iconOnly
                size="sm"
                @click="openEditPayment(c)"
                icon="fa fa-edit"
              ></base-button>
            </td>
          </tr>
        </template>

        <template #pagination v-if="page === 'incoming'">
          <base-pagination
            @input="pagination = $event"
            :value="pagination"
            :perPage="limit"
            :total="total"
          ></base-pagination>
        </template>

        <template #pagination v-else-if="page === 'payments'">
          <base-pagination
            @input="pagination = $event"
            :value="pagination"
            :perPage="limit"
            :total="openedTotal"
          ></base-pagination>
        </template>
      </projects-table>

      <filter-incomings-modal
        :shouldShow="showFilterModal"
        :fields="fieldsToShowInFilter"
        :filterOptions="filterOptions"
        @close-modal="showFilterModal = false"
        @update-filter="updateFilter($event)"
      ></filter-incomings-modal>
      <edit-payment-modal
        :shouldShow="showEditPaymentModal"
        :payment="payment"
        @reload-data="loadIncomings() | loadReports()"
        @close-modal="showEditPaymentModal = false"
      ></edit-payment-modal>
      <upload-data-modal
        @close-modal="showUploadDataModal = false"
        csvRules="id,cost,order,total,type,status,category,expires_at,paid_at,value,enrol_id,student_id"
        uploadMessage="Você está enviando dados para matrículas"
        :shouldShow="showUploadDataModal"
        :upload-method="uploadMethod"
        :allowSelectComission="true"
      ></upload-data-modal>
    </div>
  </div>
</template>

<script>
//import Card from "../components/Card.vue";
import StatsCard from "../components/StatsCard.vue";
import "jspdf-autotable";
import jsPDF from "jspdf";
import DashboardNavbar from "../layout/DashboardNavbar.vue";
import ProjectsTable from "./Tables/ProjectsTable.vue";
import "vue-loaders/dist/vue-loaders.css";
import UploadDataModal from "./Modals/UploadDataModal.vue";
import BaseLoader from "../components/BaseLoader.vue";
import { loadPayments } from "../utils/integrations";
import {
  ElTooltip,
  ElNotification,
  ElTabs,
  ElTabPane,
  ElPopover,
} from "element-plus";
import ComissionService from "../services/comissions";
import PaymentService from "../services/payments";
import FilterIncomingsModal from "./Modals/FilterIncomingsModal.vue";
import EditPaymentModal from "./Modals/EditPaymentModal.vue";
import { cpfFormatter } from "../utils/formatters";
// @ is an alias to /src

export default {
  name: "Students",
  components: {
    DashboardNavbar,
    ProjectsTable,
    StatsCard,
    BaseLoader,
    FilterIncomingsModal,
    ElTooltip,
    ElTabs,
    ElTabPane,
    EditPaymentModal,
    ElPopover,
    UploadDataModal,
  },
  data() {
    return {
      isLoading: true,
      showUploadDataModal: false,
      uploadMethod: loadPayments,
      fieldsToShowInFilter: {
        incoming: true,
        type: true,
        method: true,
        comission: true,
        expire_date: true,
        payment_date: true,
      },
      comissions: null,
      paymentsByComissions: {},
      pagination: 1,
      filterOptions: {
        paid_at_start: new Date(
          Date.UTC(new Date().getFullYear(), new Date().getMonth(), 1)
        ),
        paid_at_end: new Date(
          Date.UTC(
            new Date().getFullYear(),
            new Date().getMonth() + 1,
            0,
            29,
            59
          )
        ),
        type: ["credit", "doc", "pix"],
      },
      referenceDate: new Date(),
      sortOptions: {
        "students.name": "asc",
      },
      student: null,
      showEditPaymentModal: false,
      searchQuery: "",
      total: 0,
      dialogVisible: false,
      reportData: {},
      jsPDF: new jsPDF("landscape"),
      isLoadingGeneratePDF: false,
      limit: 25,
      payments: null,
      payment: null,
      page: "incoming",
      cpfFormatter: {
        format(cpf) {
          let cpfFormatted = `${cpf}`;
          cpfFormatted = `${"0".repeat(
            11 - cpfFormatted.length
          )}${cpfFormatted}`;

          return cpfFormatted.replace(
            /(\d{3})(\d{3})(\d{3})(\d{2})/,
            "$1.$2.$3-$4"
          );
        },
      },
      dateFormatterMonth: new Intl.DateTimeFormat("pt-br", {
        timeZone: "UTC",
        day: "numeric",
        month: "long",
        year: "numeric",
      }),
      showFilterModal: false,
      openedPayments: null,
      openedTotal: 0,
      dateFormatter: new Intl.DateTimeFormat("pt-BR"),
      currencyFormatter: new Intl.NumberFormat("pt-br", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    };
  },
  mounted() {
    this.loadIncomings();
    this.loadReports();
    this.fetchComissions();
  },
  watch: {
    SelectedSchool() {
      this.searchQuery = "";
      this.pagination = 1;
      this.loadReports();
    },
    Comissions() {
      this.fetchPaymentsByComissions();
    },
    pagination() {
      this.loadIncomings();
    },
    referenceDate() {
      this.loadReports();
    },
  },
  computed: {
    CurrentMonthInAgenda() {
      const start = new Date(this.filterOptions.paid_at_start);
      const end = new Date(this.filterOptions.paid_at_end);
      return `${
        Date.parse(start) ? this.dateFormatterMonth.format(start) : "indefinido"
      } - ${
        Date.parse(end) ? this.dateFormatterMonth.format(end) : "indefinido"
      }`;
    },
    SelectedSchool() {
      return this?.$store?.state?.user?.selectedSchool;
    },
    Payments() {
      return this.payments || [];
    },
    HasNoPayments() {
      return (this?.payments?.length || 0) === 0;
    },
    IncomingPredictionIsBetter() {
      return (
        this.reportData?.incomingPrediction >
        this.reportData?.incomingPredictionBefore
      );
    },
    Comissions() {
      return this.comissions;
    },
    TableColumns() {
      const tableHeaders = [
        {
          label: "CPF",
          sortable: true,
          prop: "students.cpf",
        },
        {
          label: "NOME",
          sortable: true,
          prop: "students.name",
        },
        {
          label: "CURSO",
          sortable: true,
          prop: "courses.name",
        },
        {
          label: "ORDEM",
          sortable: true,
          prop: "order",
        },
        {
          label: "TIPO",
          sortable: false,
        },
        {
          label: "FORMA",
          sortable: false,
        },
        {
          label: "VENCIMENTO",
          sortable: true,
          prop: "expires_at",
        },
        {
          label: "PAGAMENTO",
          sortable: true,
          prop: "paid_at",
        },
        {
          label: "PAGO",
          sortable: true,
          prop: "value",
        },
      ];

      if (this.userCan("comissions.r"))
        tableHeaders.push({
          label: "COMISSÃO",
          sortable: true,
          prop: "comission",
        });

      tableHeaders.push({
        label: "CONFERE",
        sortable: false,
      });

      return tableHeaders;
    },
    TableColumnsOpenedPayments() {
      const tableHeaders = [
        {
          label: "CPF",
          sortable: true,
          prop: "students.cpf",
        },
        {
          label: "NOME",
          sortable: true,
          prop: "students.name",
        },
        {
          label: "CURSO",
          sortable: true,
          prop: "courses.name",
        },
        {
          label: "A PAGAR",
          sortable: true,
          prop: "cost",
        },
        {
          label: "ORDEM",
          sortable: true,
          prop: "order",
        },
        {
          label: "TIPO",
          sortable: false,
        },
        {
          label: "FORMA",
          sortable: false,
        },
        {
          label: "VENCIMENTO",
          sortable: true,
          prop: "expires_at",
        },
      ];

      if (this.userCan("comissions.r"))
        tableHeaders.push({
          label: "COMISSÃO",
          sortable: true,
          prop: "comission",
        });

      tableHeaders.push({
        label: "AÇÕES",
        sortable: false,
      });

      return tableHeaders;
    },
  },
  methods: {
    fetchPaymentsByComissions() {
      const self = this;
      if (this.Comissions.length) {
        this.Comissions.map(async (comission) => {
          const { payments } = await self.fetchPayments({
            ...this.filterOptions,
            comissions: comission.uid,
            school_uid: self.SelectedSchool.uid,
            status: "paid",
          });

          if (payments)
            self.paymentsByComissions[comission.uid] =
              self.sumPayments(payments);
        });
      }
    },
    userCan(permission) {
      const { authorizations } = this.$store.state.user?.role || {};

      if (authorizations) {
        const [entity, action] = permission.split(".");

        if (authorizations[entity] && authorizations[entity].includes(action))
          return true;
      }

      return false;
    },
    openUploadDataModal() {
      this.showUploadDataModal = true;
    },
    async fetchComissions() {
      const { comissions } = await ComissionService().index({
        school_uid: this.SelectedSchool?.uid,
      });

      if (comissions) this.comissions = comissions;
    },
    fetchPayments(filters) {
      return PaymentService().index(filters);
    },
    loadReports() {
      this.fetchIncomingTotal();
    },
    async getPaymentsByEnrol(enrolId, isPaymentChecked){
      const { payments } = await this.fetchPayments({enrol_id: enrolId, school_uid: this.$store.state.user.selectedSchool?.uid})
      const self = this;
      return payments?.filter((p) => isPaymentChecked ? self.isCheckedThisMonth(p) : !this.isCheckedThisMonth(p));
    },
    async checkPayment(c) {
      if (this.isCheckedThisMonth(c)) {
        c.checked_at = null;
        await this.updatePayment(c);
      } else {
        c.checked_at = new Date();
        await this.updatePayment(c);
      }
    },
    async checkAllUncheckedPayments(payment){
      const isPaymentChecked = this.isCheckedThisMonth(payment);
      this.checkPayment(payment);

      const uncheckedPayments = (await this.getPaymentsByEnrol(payment.enrol.uid, isPaymentChecked)).filter(p => p.uid !== payment.uid);
      const queries = uncheckedPayments?.map(this.checkPayment);
      await Promise.all(queries);
      this.loadIncomings();
    },
    fetchIncomingTotal() {
      const url = new URL(`${this.$store.state.apiUrl}payments/sum`);
      url.search = new URLSearchParams({
        ...this.filterOptions,
        school_uid: this.SelectedSchool.uid,
        status: "paid",
      });
      fetch(url, {
        credentials: "include",
        headers: {
          Accept: "application/json",
        },
      })
        .then((r) => r.json())
        .then((json) => {
          this.reportData["incomingTotal"] = json?.incomingValue || 0;
          this.reportData["incomingValuePaid"] = json?.incomingValuePaid || 0;
          this.reportData["incomingValueReceived"] =
            json?.incomingValueReceived || 0;
          this.reportData["taxValue"] = json?.taxValue || 0;
          this.reportData["incomingDocPaid"] = json?.docPaid || 0;
          this.reportData["incomingDocReceived"] = json?.docReceived || 0;
          this.reportData["incomingCreditPaid"] = json?.creditPaid || 0;
          this.reportData["incomingCreditReceived"] = json?.creditReceived || 0;
          this.reportData["incomingFreeReceived"] = json?.freePaid || 0;
        });
    },
    sumPayments(payments) {
      return payments?.reduce((t, p) => t + Number(p.amount_received), 0);
    },
    sortColumn(v) {
      if (this.sortOptions[v.prop]) {
        if (this.sortOptions[v.prop] != "asc") this.sortOptions[v.prop] = "asc";
        else delete this.sortOptions[v.prop];
      } else this.sortOptions[v.prop] = "desc";

      this.fetchData()
        .then((response) => {
          if (response.status == 200) return response.json();
          else throw response.json();
        })
        .then((json) => {
          this.payments = json.data;
          this.total = json.total;
        });
    },
    calculateSummaries({ data }) {
      let sumTotalCost = 0;
      let sumTotalPaid = 0;
      let sumReceived = 0;
      for (let p of data) {
        sumTotalCost += Number.parseFloat(p.cost);
        if (p.value) sumTotalPaid += Number.parseFloat(p.value);
        if (p.value && p.status === "paid")
          sumReceived += Number.parseFloat(p.value * p.comission);
      }

      return [
        "Total:",
        "",
        `${this.currencyFormatter.format(sumTotalCost)}`,
        "",
        "",
        "",
        "",
        "",
        `${this.currencyFormatter.format(sumTotalPaid)}`,
        `${this.currencyFormatter.format(sumReceived)}`,
        "",
      ];
    },
    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [...arrData.map((item) => Object.values(item).join(";"))]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "Exportação.csv");
      link.click();
    },
    setReceivedPayment(payment) {
      fetch(`${this.$store.state.apiUrl}payments/${payment.uid}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          is_received: !payment.is_received,
        }),
      })
        .then(() => (payment.is_received = !payment.is_received))
        .finally(() => this.loadReports());
    },
    openEditPayment(payment) {
      this.payment = payment;
      this.showEditPaymentModal = true;
    },
    setReferenceDateMonth(month) {
      this.referenceDate.setMonth(this.referenceDate.getMonth() + month);
      this.referenceDate = new Date(this.referenceDate);
    },
    async generateCSV() {
      this.isLoadingGeneratePDF = true;
      ElNotification.info({
        title: "Gerando relatório",
        message: "Isso pode levar até 1 minuto",
      });

      const data = await this.fetchData(
        false,
        this.page === "payments" ? "opened" : "paid"
      ).then((response) => response.json());

      const mappedRows = data.map((e) => [
        e?.student?.code,
        cpfFormatter.format(e?.student?.cpf),
        e?.student?.name,
        e?.enrol?._course?.name,
        this.currencyFormatter.format(Number(e?.cost)),
        e?.order,
        e?.total,
        e.category || "-",
        e.type || "-",
        e?.expires_at
          ? this.dateFormatter.format(new Date(e?.expires_at))
          : "-",
        e?.paid_at ? this.dateFormatter.format(new Date(e?.paid_at)) : "-",
        e?.value ? this.currencyFormatter.format(Number(e?.value)) : "-",
        this.userCan("comissions.r") ? `${e.comission * 100} %` : "",
        this.userCan("comissions.r")
          ? `${this.currencyFormatter.format(
              Number(e?.value) * Number(e?.comission)
            )}`
          : "",
        e.enrol?.comission?.name,
      ]);

      const columns = [
        "CODIGO",
        "CPF",
        "NOME",
        "CURSO",
        "A PAGAR",
        "ORDEM",
        "TOTAL",
        "TIPO",
        "FORMA",
        "VENCIMENTO",
        "PAGAMENTO",
        "PAGO",
        "COMISSÃO",
        "A RECEBER.",
        "NOME COMIS.",
      ];

      mappedRows.unshift(columns);

      this.csvExport(mappedRows);

      this.isLoadingGeneratePDF = false;
    },
    async generatePDF() {
      this.isLoadingGeneratePDF = true;
      ElNotification.info({
        title: "Gerando relatório",
        message: "Isso pode levar até 1 minuto",
      });

      const data = await this.fetchData(
        false,
        this.page === "payments" ? "opened" : "paid"
      ).then((response) => response.json());

      const mappedRows = data.map((e) => [
        e?.student?.code,
        e?.student?.name,
        e?.enrol?._course?.name,
        `${e?.order}/${e?.total}`,
        e.category || "-",
        e.type || "-",
        e?.expires_at
          ? this.dateFormatter.format(new Date(e?.expires_at))
          : "-",
        e?.paid_at ? this.dateFormatter.format(new Date(e?.paid_at)) : "-",
        e?.value ? this.currencyFormatter.format(Number(e?.value)) : "-",
        this.userCan("comissions.r") ? `${e.comission * 100} %` : "",
      ]);

      mappedRows.push(this.calculateSummaries({ data }));

      const columns = this.TableColumns;
      columns.length--;
      this.jsPDF.autoTable({
        columns: this.TableColumns.map((c) => c.label),
        body: mappedRows,
      });

      this.jsPDF.save(`repasse.pdf`);
      this.isLoadingGeneratePDF = false;
    },
    getFileName() {
      const options = this.filterOptions;
      let name = "";
      name += "Relatório de Repasse: ";
      for (let f in options) {
        switch (f) {
          case "type":
            name += this.getPaymentType(options[f]);
            break;
          case "status":
            name +=
              options[f] === "paid"
                ? "Pago"
                : options[f] === "opened"
                ? "Aberto"
                : options[f] === "unpaid"
                ? "Atrasado"
                : options[f] === "closed"
                ? "Fechado"
                : "";
            break;
          case "category":
            name += this.getPaymentCategory(options[f]);
            break;
          case "is_received":
            name += options[f] ? "Recebido" : "Não Recebido";
            break;
        }
      }

      return name;
    },
    getPaymentCategory: (category) =>
      ({ renegotiation: "RN", monthly: "MN", tax: "MT" }[category] || "MN"),
    getPaymentType: (type) =>
      ({ credit: "crédito", doc: "boleto", pix: "transf.", polo: "polo" }[
        type
      ] || "desconhecido"),
    updateFilter(v) {
      this.filterOptions = v;
      this.loadIncomings();
      this.fetchIncomingTotal();
      this.fetchPaymentsByComissions();
    },
    isEmpty(o) {
      for (let i in o) return false;
      return true;
    },
    updateSearch(v) {
      this.searchQuery = v;
      this.pagination = 1;
      this.loadIncomings();
      this.fetchIncomingTotal();
    },
    fetchData(paginate = true, status = "paid", enrolStatusToExclude) {
      const url = new URL(this.$store.state.apiUrl + "payments");
      const params = {
        school_uid: this.SelectedSchool?.uid,
        ...(this.filterOptions || {}),
        status,
        type: this.filterOptions?.type || "credit,doc,pix",
      };

      if (enrolStatusToExclude)
        params.enrol_status_to_exclude = enrolStatusToExclude;

      if (paginate) {
        params.pagination = this.pagination || 1;
        params.limit = this.limit || 15;
      }
      params.queryName = this.searchQuery || "";
      params.ordenation = JSON.stringify(this.sortOptions);

      url.search = new URLSearchParams(params);
      return fetch(url, {
        credentials: "include",
        //mode: "no-cors",
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });
    },
    getRowClassNames({ row }) {
      return row.payment_status;
    },
    loadIncomings() {
      this.isLoading = true;
      this.fetchData(true, "paid")
        .then((response) => response.json())
        .then((json) => {
          this.payments = json.data?.filter((o) => o.type != "Ao Polo");
          this.total = json.total;
        })
        .then(() => (this.isLoading = false));

      this.fetchData(true, "opened", "pending")
        .then((response) => response.json())
        .then((json) => {
          this.openedPayments = json?.data?.filter((o) => o.type != "Ao Polo");
          this.openedTotal = json.total;
        });
    },
    navigateToRoute(items) {
      let route = this.$router.resolve(`/alunos/${items.student.uid}`);
      window.open(route.href, "_blank");
    },
    openStudentPage(payment) {
      if (payment) this.navigateToRoute(payment);
    },
    hasValue(item, column) {
      return item[column] !== "undefined";
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    openFilterModal() {
      this.showFilterModal = true;
    },
    isCheckedThisMonth(payment) {
      if (!payment.checked_at) return false;

      const start = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      );
      const end = new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        0,
        23,
        59
      );
      return (
        start <= new Date(payment.checked_at) &&
        end >= new Date(payment.checked_at)
      );
    },
    async updatePayment(p) {
      if (!p)
        return;

      const {payment} = await PaymentService(p.uid).update(p);
      return payment;
    },
    showAddStudent(v) {
      this.showAddStudentModal = true;
      this.student = v;
    },
  },
};
</script>
<style>
tbody.list {
  overflow-x: hidden;
  width: 100%;
}
.is-active {
  color: #1565c0 !important;
}
tr.unpaid {
  background-color: #ffebee;
}
</style>
