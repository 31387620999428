<template>
  <div>
    <dashboard-navbar
      searchHint="Buscar aluno ou curso..."
      @update-search="updateSearch"
    ></dashboard-navbar>

    <div class="container-fluid d-flex h-100 flex-column pt-6 px-2">
      <base-loader v-if="isLoading"></base-loader>
      <projects-table
        v-else
        show-summary
        class="table-responsive table-flush"
        header-row-class-name="table-head"
        header-align="center"
        :tableData="Exams?.data"
        cell-class-name="reduce-padding"
        hasInlineRoute
        :row-class-name="getRowClassNames"
        type="hover"
        title="Alunos em período de prova"
        @showModal="showAddStudentModal = $event"
      >
        <template #actions>
          <div class="row justify-content-end mr-0">
            <el-tabs v-model="page">
              <el-tab-pane
                label="Realizadas"
                value="realized"
                name="realized"
              ></el-tab-pane>
              <el-tab-pane
                label="Disponíveis"
                value="available"
                name="available"
              ></el-tab-pane>
              <el-tab-pane
                label="Pendentes"
                value="expired"
                name="expired"
              ></el-tab-pane>
            </el-tabs>
            <div class="col-md-3 col-sm-4 pr-0">
              <base-button
                type="primary"
                icon="fa fa-filter"
                title="Filtrar"
                class="mt-2"
                size="sm"
                tooltip="Filtrar matrículas em período de prova"
                v-on:click.stop.prevent="openFilterModal"
              ></base-button>

              <el-popover
                placement="bottom"
                :width="300"
                v-model="dialogVisible"
              >
                <p>Em qual formato deseja baixar?</p>
                <div style="text-align: right; margin: 0">
                  <base-button
                    size="sm"
                    type="text"
                    disabled
                    @click="dialogVisible = false"
                    >Excel</base-button
                  >
                  <base-button
                    size="sm"
                    type="text"
                    @click="() => generateCSV((dialogVisible = false))"
                    >CSV</base-button
                  >
                  <base-button
                    type="text"
                    size="sm"
                    v-on:click.stop.prevent="
                      () => generatePDF((dialogVisible = false))
                    "
                    >PDF</base-button
                  >
                </div>
                <template #reference>
                  <base-button
                    type="primary"
                    icon="fa fa-download"
                    class="mt-2"
                    size="sm"
                    :disabled="isLoadingGeneratePDF"
                  ></base-button>
                </template>
              </el-popover>
            </div>
          </div>
        </template>
        <template #header>
          <div class="row justify-content-between mx-3 pb-2">
            <h3>
              {{ CurrentMonthInAgenda }}
            </h3>
            <div class="row">
              <base-button
                type="primary"
                size="sm"
                @click="setReferenceDateMonth(-1)"
                icon="ni ni-bold-left"
              ></base-button>
              <base-button
                type="primary"
                size="sm"
                @click="
                  setReferenceDateMonth(-1 * referenceDate.getMonth() - 1)
                "
                >ano passado</base-button
              >
              <base-button
                type="primary"
                size="sm"
                @click="setReferenceDateMonth(-2)"
                >-2 meses</base-button
              >
              <base-button
                type="primary"
                size="sm"
                @click="referenceDate = new Date()"
                >hoje</base-button
              >
              <base-button
                type="primary"
                size="sm"
                @click="setReferenceDateMonth(2)"
                >+2 meses</base-button
              >

              <base-button
                type="primary"
                size="sm"
                @click="setReferenceDateMonth(12 - referenceDate.getMonth())"
                >próximo ano</base-button
              >

              <base-button
                type="primary"
                size="sm"
                @click="setReferenceDateMonth(1)"
                icon="ni ni-bold-right"
              ></base-button>
            </div>
          </div>
        </template>
        <template #columns>
          <th v-for="col in TableColumns" :key="col">
            {{ col.label }}
            <span
              class="caret-wrapper"
              v-if="col?.sortable"
              @click="sortColumn(col)"
            >
              <i
                :class="{
                  'fa fa-sort-up ascending sort-icon': true,
                  'is-sorted': sortOptions[col.prop] === 'asc',
                }"
              ></i>
              <i
                :class="{
                  'fa fa-sort-down descending sort-icon': true,
                  'is-sorted': sortOptions[col.prop] === 'desc',
                }"
              ></i>
            </span>
          </th>
        </template>

        <template #data v-if="Exams?.data?.length">
          <tr
            v-for="(c, id) in Exams?.data"
            :key="id"
            style="cursor: pointer"
            v-on:click="navigateToRoute(c)"
          >
            <td>
              <div>
                {{ cpfFormatter.format(c?.student?.cpf) }}
              </div>
            </td>
            <td class="wrap-content">
              <div>{{ c.student.name }}</div>
            </td>

            <td>
              <div>
                {{
                  c.student?.contact?.phone
                    ? phoneFormatter.format(Number(c.student?.contact?.phone))
                    : "-"
                }}
              </div>
            </td>
            <td class="wrap-content">
              {{ c.enrol._course.name }}
            </td>

            <td>
              <div>
                {{
                  c.enrol.periods
                    ? `${c.period.months} / ${c.period.total_months}`
                    : "-"
                }}
              </div>
            </td>

            <td>
              <div>
                {{ c.enrol?.periods_formatted }}
              </div>
            </td>

            <td>
              <div>
                {{
                  c.starts_in
                    ? dateFormatterShort.format(new Date(c.starts_in))
                    : "-"
                }}
                -
                {{
                  c.expires_in
                    ? dateFormatterShort.format(new Date(c.expires_in))
                    : "-"
                }}
              </div>
            </td>

            <td>
              <div>
                {{
                  c?.schedule ? new Date(c?.schedule).toLocaleDateString() : ""
                }}
              </div>
            </td>

            <td>
              <div class="btn-group float-right">
                <base-button
                  :type="c.schedule ? 'secondary' : 'primary'"
                  iconOnly
                  size="sm"
                  :tooltip="
                    c.schedule
                      ? 'Esta prova já foi agendada'
                      : 'Clique para agendar a prova'
                  "
                  @click="openExamModal(c)"
                  icon="fa fa-calendar"
                ></base-button>
                <el-popconfirm
                  title="Informe se o aluno foi aprovado"
                  confirm-button-text="Aprovado"
                  cancel-button-text="Reprovado"
                  hide-icon
                  @confirm="realizeExam(true, c)"
                  @cancel="realizeExam(false, c)"
                >
                  <template #reference>
                    <base-button
                      :type="
                        c.schedule
                          ? !c.is_realized
                            ? 'primary'
                            : c.is_approved
                            ? 'success'
                            : 'danger'
                          : 'secondary'
                      "
                      iconOnly
                      size="sm"
                      :disabled="!c.schedule"
                      icon="fa fa-check"
                    ></base-button>
                  </template>
                </el-popconfirm>
                <el-popconfirm
                  title="Excluir periodo de provas"
                  confirm-button-text="Excluir"
                  cancel-button-text="Cancelar"
                  hide-icon
                  @confirm="deleteExam(c)"
                >
                  <template #reference>
                    <base-button
                      type="danger"
                      iconOnly
                      size="sm"
                      icon="fa fa-trash"
                    ></base-button>
                  </template>
                </el-popconfirm>
              </div>
            </td>
          </tr>
        </template>

        <template #pagination>
          <base-pagination
            @input="pagination = $event"
            :value="pagination"
            :perPage="limit"
            :total="Exams?.total"
          ></base-pagination>
        </template>
      </projects-table>
      <filter-exams-modal
        :shouldShow="showFilterModal"
        @close-modal="showFilterModal = false"
        @update-filter="updateFilter($event)"
      ></filter-exams-modal>
      <re-enroll-modal
        :exam="exam"
        :shouldShow="showReenrollModal"
        :studentData="student"
        :enrolData="enrol"
        @close-modal="showReenrollModal = false"
        @reload-data="loadExams()"
      ></re-enroll-modal>
      <exam-modal
        :exam="exam"
        :shouldShow="showExamModal"
        @close-modal="showExamModal = false"
        @reload-data="loadExams()"
      ></exam-modal>
    </div>
  </div>
</template>

<script>
//import Card from "../components/Card.vue";
import "jspdf-autotable";
import jsPDF from "jspdf";
import DashboardNavbar from "../layout/DashboardNavbar.vue";
import ProjectsTable from "./Tables/ProjectsTable.vue";
import "vue-loaders/dist/vue-loaders.css";
import BaseLoader from "../components/BaseLoader.vue";
import ExamService from "../services/exams";
import {
  ElNotification,
  ElPopconfirm,
  ElTabs,
  ElTabPane,
  ElPopover,
} from "element-plus";
import FilterExamsModal from "./Modals/FilterExamsModal.vue";
import ExamModal from "./Modals/ExamModal.vue";
import ReEnrollModal from "./Modals/ReEnrollModal.vue";
import { notifySuccess } from "../utils/notifiers";
// @ is an alias to /src

export default {
  name: "Students",
  components: {
    DashboardNavbar,
    ProjectsTable,
    BaseLoader,
    FilterExamsModal,
    ExamModal,
    ReEnrollModal,
    ElPopconfirm,
    ElTabs,
    ElTabPane,
    ElPopover,
  },
  data() {
    return {
      isLoading: true,
      pagination: 1,
      filterOptions: {},
      sortOptions: {},
      student: null,
      searchQuery: "",
      total: 0,
      showExamModal: false,
      exam: null,
      enrol: null,
      page: "available",
      jsPDF: new jsPDF("landscape"),
      isLoadingGeneratePDF: false,
      limit: 15,
      availableExams: null,
      completedExams: null,
      pendingExams: null,
      referenceDate: new Date(),
      showFilterModal: false,
      showReenrollModal: false,
      dateFormatter: new Intl.DateTimeFormat("pt-BR"),
      dateFormatterShort: new Intl.DateTimeFormat("pr-BR", {
        month: "numeric",
        year: "numeric",
      }),
      dateFormatterLong: new Intl.DateTimeFormat("pr-BR", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      }),
      dateFormatterMonth: new Intl.DateTimeFormat("pt-br", {
        month: "long",
        year: "numeric",
      }),
      cpfFormatter: {
        format(cpf) {
          let cpfFormatted = `${cpf}`;
          cpfFormatted = `${"0".repeat(
            11 - cpfFormatted.length
          )}${cpfFormatted}`;

          return cpfFormatted.replace(
            /(\d{3})(\d{3})(\d{3})(\d{2})/,
            "$1.$2.$3-$4"
          );
        },
      },
      phoneFormatter: {
        format(phone) {
          if (phone) {
            let phoneFormatted = `${phone}`;
            switch (phoneFormatted.length) {
              case 9:
              case 11:
                return phoneFormatted.replace(
                  /(\d{2})(\d{1})(\d{4})(\d{4})/,
                  "($1)$2 $3-$4"
                );
              default:
                return phoneFormatted.replace(
                  /(\d{2})(\d{4})(\d{4})/,
                  "($1)$2-$3"
                );
            }
          } else return phone;
        },
      },
      currencyFormatter: new Intl.NumberFormat("pt-br", {
        minimumFractionDigits: 2,
      }),
    };
  },
  created() {
    this.loadExams();
  },
  watch: {
    SelectedSchool() {
      this.pagination = 1;
      this.searchQuery = "";
      this.loadExams();
    },
    pagination() {
      this.loadExams();
    },
    referenceDate() {
      this.loadExams();
    },
  },
  computed: {
    CurrentMonthInAgenda() {
      return this.dateFormatterMonth.format(this.referenceDate);
    },
    SelectedSchool() {
      return this?.$store?.state?.user?.selectedSchool;
    },
    Exams() {
      let result = [];
      switch (this.page) {
        case "available":
          result = this?.availableExams;
          break;
        case "realized":
          result = this?.completedExams;
          break;
        case "expired":
          result = this?.pendingExams;
      }

      return result;
    },
    TableColumns: () => [
      {
        label: "cpf",
        sortable: true,
        prop: "students.code",
      },
      {
        label: "nome",
        sortable: true,
        prop: "students.name",
      },
      {
        label: "contato",
        sortable: true,
        prop: "contact.phone",
      },
      {
        label: "curso",
        sortable: true,
        prop: "courses.name",
      },
      {
        label: "duração",
        sortable: true,
        prop: "enrol_periods.starts_at",
      },
      {
        label: "período",
        sortable: false,
      },
      {
        label: "prova",
        sortable: true,
        prop: "starts_in",
      },
      {
        label: "data",
        sortable: true,
        prop: "realized_at",
      },
      {
        label: "ações",
        sortable: false,
      },
    ],
  },
  methods: {
    setReferenceDateMonth(month) {
      this.referenceDate.setMonth(this.referenceDate.getMonth() + month);
      this.referenceDate = new Date(this.referenceDate);
    },
    openReenrollModal(exam) {
      this.student = exam.student;
      this.enrol = {
        ...(exam.enrol || {}),
        periods: exam?.period?.total || 1,
      };
      this.showReenrollModal = true;
    },
    async sortColumn(v) {
      if (this.sortOptions[v.prop]) {
        if (this.sortOptions[v.prop] != "asc") this.sortOptions[v.prop] = "asc";
        else delete this.sortOptions[v.prop];
      } else this.sortOptions[v.prop] = "desc";

      const exams = await this.fetchData().then((response) => {
        if (response.status == 200) return response.json();
        else throw response.json();
      });

      switch (this.page) {
        case "available":
          this.availableExams = exams;
          break;
        case "expired":
          this.pendingExams = exams;
          break;
        case "realized":
          this.completedExams = exams;
          break;
      }
    },
    realizeExam(result, exam) {
      exam.is_realized = true;
      exam.is_approved = result;
      delete exam.schedule;
      this.openReenrollModal(exam);
      this.updateExam(exam);
    },
    async deleteExam(e){
      const { exam } = await ExamService(e.uid).delete();
      if(exam){
        notifySuccess("Período de prova removido com sucesso")
        this.fetchData();
      }
    },
    openExamModal(exam) {
      this.exam = { ...exam, obs: this.findExamObs(exam)?.description };
      this.showExamModal = true;
    },
    findExamObs(exam) {
      return exam?.student?.observations?.find((o) =>
        o?.description?.includes(exam?.name)
      );
    },
    updateExam(exam) {
      fetch(`${this.$store.state.apiUrl}exams/${exam.uid}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          ...exam,
          student: { uid: exam.student.uid },
        }),
      })
        .then(async (response) => {
          if (response.status == 200) {
            this.errorMessages = {};
            this.loadExams();

            return response.json();
          }
          throw await response.json();
        })
        .then(
          (json) =>
            ElNotification.success({
              title: json.message,
            }),
          (e) =>
            ElNotification.error({
              message: e.message,
            })
        )
        .finally(() => (this.isLoadingSubmit = false));
    },
    async generateCSV() {
      this.isLoadingGeneratePDF = true;
      ElNotification.info({
        title: "Gerando relatório",
        message: "Isso pode levar até 1 minuto",
      });

      var filters;

      switch (this.page) {
        case "available":
          filters = {
            is_realized: false,
            period_end: new Date(
              this.referenceDate.getFullYear(),
              this.referenceDate.getMonth() + 1,
              0
            ),
          };
          break;
        case "expired":
          filters = {
            is_realized: false,
            period_start: new Date(this.referenceDate.getFullYear() - 1, 1, 1),
            period_end: new Date(
              this.referenceDate.getFullYear(),
              this.referenceDate.getMonth()
            ),
          };
          break;
        case "realized":
          filters = {
            is_realized: true,
            schedule_start: new Date(
              this.referenceDate.getFullYear(),
              this.referenceDate.getMonth(),
              2
            ),
            schedule_end: new Date(
              this.referenceDate.getFullYear(),
              this.referenceDate.getMonth() + 1,
              0
            ),
          };
          break;
      }

      const data = await this.fetchData(false, filters).then((response) =>
        response.json()
      );

      const mappedRows = data.map((e) => [
        this.cpfFormatter.format(e?.student?.cpf),
        e?.student?.name,
        this.phoneFormatter.format(e?.student?.contact?.phone || "-"),
        e?.enrol?._course?.name || "",
        e?.period?.months,
        e?.period?.total_months,
        e?.enrol?.current_period,
        e?.period?.total,
        e.starts_in
          ? this.dateFormatterShort.format(new Date(e.starts_in))
          : "",
        e.expires_in
          ? this.dateFormatterShort.format(new Date(e.expires_in))
          : "",
        e?.schedule
          ? this.dateFormatterLong.format(new Date(e?.schedule))
          : "-",
      ]);

      const columns = [
        "cpf",
        "nome",
        "telefone",
        "curso",
        "mes atual",
        "total meses",
        "periodo atual",
        "total periodos",
        "periodo de prova",
        "agendamento",
      ];
      mappedRows.unshift(columns);

      this.csvExport(mappedRows);

      this.isLoadingGeneratePDF = false;
    },
    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [...arrData.map((item) => Object.values(item).join(";"))]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "Exportação.csv");
      link.click();
    },
    async generatePDF() {
      this.isLoadingGeneratePDF = true;
      ElNotification.info({
        title: "Gerando relatório",
        message: "Isso pode levar até 1 minuto",
      });

      var filters;

      switch (this.page) {
        case "available":
          filters = {
            is_realized: false,
            period_end: new Date(
              this.referenceDate.getFullYear(),
              this.referenceDate.getMonth() + 1,
              0
            ),
          };
          break;
        case "expired":
          filters = {
            is_realized: false,
            period_start: new Date(this.referenceDate.getFullYear() - 1, 1, 1),
            period_end: new Date(
              this.referenceDate.getFullYear(),
              this.referenceDate.getMonth()
            ),
          };
          break;
        case "realized":
          filters = {
            is_realized: true,
            schedule_start: new Date(
              this.referenceDate.getFullYear(),
              this.referenceDate.getMonth(),
              2
            ),
            schedule_end: new Date(
              this.referenceDate.getFullYear(),
              this.referenceDate.getMonth() + 1,
              0
            ),
          };
          break;
      }

      const data = await this.fetchData(false, filters).then((response) =>
        response.json()
      );

      const mappedRows = data.map((e) => [
        this.cpfFormatter.format(e?.student?.cpf),
        e?.student?.name,
        this.phoneFormatter.format(e?.student?.contact?.phone || "-"),
        e?.enrol?._course?.name || "",
        `${e?.period?.months}/${e?.period?.total_months}`,
        `${e?.enrol?.periods_formatted || "-"}`,
        `${
          e.starts_in
            ? this.dateFormatterShort.format(new Date(e.starts_in))
            : ""
        }-${
          e.expires_in
            ? this.dateFormatterShort.format(new Date(e.expires_in))
            : ""
        }`,
        e?.schedule
          ? this.dateFormatterLong.format(new Date(e?.schedule))
          : "-",
      ]);

      const columns = [
        "cpf",
        "nome",
        "telefone",
        "curso",
        "meses",
        "periodos",
        "periodo de prova",
        "agendamento",
      ];
      this.jsPDF.autoTable({
        columns,
        body: mappedRows,
      });
      this.jsPDF.save(this.getFileName());
      this.jsPDF = new jsPDF("landscape");
      this.isLoadingGeneratePDF = false;
    },
    getFileName() {
      return "Agenda de Provas";
    },
    updateFilter(v) {
      this.filterOptions = v;
      this.loadExams();
    },
    isEmpty(o) {
      for (let i in o) return false;
      return true;
    },
    updateSearch(v) {
      this.searchQuery = v;
      this.pagination = 1;
      this.loadExams();
    },
    getFirstElementSafe(o, d) {
      if (o?.length) return o[0];
      else return d;
    },
    fetchData(paginate = true, filters) {
      const url = new URL(this.$store.state.apiUrl + "exams");
      const params = {
        school_uid: this.SelectedSchool?.uid,
        ...(this.filterOptions ? this.filterOptions : {}),
        ...(filters || {}),
      };

      if (paginate) {
        params.pagination = this.pagination || 1;
        params.limit = this.limit || 15;
      }
      params.queryName = this.searchQuery || "";
      params.ordenation = JSON.stringify(this.sortOptions);

      url.search = new URLSearchParams(params);
      return fetch(url, {
        credentials: "include",
        //mode: "no-cors",
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });
    },
    getRowClassNames({ row }) {
      return row.payment_status;
    },
    async loadExams() {
      this.isLoading = true;
      this.availableExams = await this.fetchData(true, {
        is_realized: false,
        period_start: new Date(
          this.referenceDate.getFullYear(),
          this.referenceDate.getMonth(),
          1
        ),
        period_end: new Date(
          this.referenceDate.getFullYear(),
          this.referenceDate.getMonth() + 1,
          0
        ),
      }).then(async (response) => await response.json());

      this.completedExams = await this.fetchData(true, {
        is_realized: true,
        schedule_start: new Date(
          this.referenceDate.getFullYear(),
          this.referenceDate.getMonth(),
          2
        ),
        schedule_end: new Date(
          this.referenceDate.getFullYear(),
          this.referenceDate.getMonth() + 1,
          0
        ),
      }).then(async (response) => await response.json());

      this.pendingExams = await this.fetchData(true, {
        is_realized: false,
        period_start: new Date(this.referenceDate.getFullYear() - 1, 1, 1),
        period_end: new Date(
          this.referenceDate.getFullYear(),
          this.referenceDate.getMonth()
        ),
      }).then(async (response) => await response.json());

      this.isLoading = false;
    },
    navigateToRoute(items) {
      let route = this.$router.resolve(`/alunos/${items.student.uid}`);
      window.open(route.href, "_blank");
    },
    hasValue(item, column) {
      return item[column] !== "undefined";
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    openFilterModal() {
      this.showFilterModal = true;
    },
    showAddStudent(v) {
      this.showAddStudentModal = true;
      this.student = v;
    },
  },
};
</script>
<style scoped>
tbody.list {
  overflow-x: hidden;
  width: 100%;
}
</style>
